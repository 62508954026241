import Seo from 'src/components/seo/Seo'

import EcoDesignMontanhaComponent from '../../components/Campanha/EcoDesignMontanhaComponent'

function EcodesignMontanha() {
  return (
    <>
      <Seo title="Campanha | Decathlon" />
      <EcoDesignMontanhaComponent />
    </>
  )
}

export default EcodesignMontanha
